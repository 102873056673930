import { defaultTheme, VpTheme } from '@vpharm-platform/shared'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { mediaLargerThan } from '../../../../common/styles/variables'

const Container = styled.div<{ vpTheme: VpTheme }>`
  width: 100%;
  background-color: ${(props) => props.vpTheme.colors['primary-900'] ?? defaultTheme.colors['primary-900']};
  padding: 1.1875rem;
  border-radius: 1rem;

  svg {
    width: 64px;
    height: 64px;
  }

  ${mediaLargerThan.mobile} {
    display: flex;
    gap: 1rem;
  }
`

const TextContainer = styled.div<{ vpTheme: VpTheme }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.vpTheme.colors.white ?? defaultTheme.colors.white};

  h5 {
    -webkit-text-fill-color: ${(props) => props.vpTheme.colors.white ?? defaultTheme.colors.white};
  }
`

const Link = styled(RouterLink)<{ vpTheme: VpTheme }>`
  color: ${(props) => props.vpTheme.colors.white ?? defaultTheme.colors.white};
`

export const StyledSavingsCardBanner = {
  TextContainer,
  Container,
  Link,
}
