import { Header, Text } from '@truepill/react-capsule'
import styled from 'styled-components/macro'

import { ThemedComponent } from '../../../../common/styledComponents/types'
import { mediaLargerThan } from '../../../../common/styles/variables'

const StyledHeader = styled(Header)`
  max-width: 360px;
  ${mediaLargerThan.tablet} {
    max-width: 700px;
  }
`

const CancelOrderConfirmationText = styled(Text)<ThemedComponent>`
  color: ${({ vpTheme }) => vpTheme.colors['typography-dark']};
`

const StyledActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  > *:first-child {
    margin-top: 0.5rem;
  }
  @media (min-width: 540px) {
    flex-flow: row;
    flex-wrap: nowrap;
    > *:first-child {
      margin-top: 0;
    }
    justify-content: flex-end;
  }
`

export { CancelOrderConfirmationText, StyledActionContainer, StyledHeader }
