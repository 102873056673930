import { Header, Text } from '@truepill/react-capsule'
import { VpTheme } from '@vpharm-platform/shared'

import { IconCoupon } from '../../../../assets/Icons'
import { PRESCRIPTION_MANAGEMENT_PATH } from '../../../../constants'
import { useAnalytics } from '../../../../hooks/analytics-context'
import { StyledSavingsCardBanner } from './styledComponents'

interface SavingsCardBannerProps {
  theme: VpTheme
}

const SavingsCardBanner = ({ theme }: SavingsCardBannerProps): React.ReactElement => {
  const { trackButtonClickEvent } = useAnalytics()

  const onRxmLinkClick = () => {
    trackButtonClickEvent('insurance_management_savings_card_banner_rxm_link', 'Prescription Manager')
  }

  return (
    <StyledSavingsCardBanner.Container vpTheme={theme}>
      <IconCoupon color={theme.colors['primary-500']} />
      <StyledSavingsCardBanner.TextContainer vpTheme={theme}>
        <Header variant='2xl'>Want to add a savings card?</Header>
        <Text>
          Return to{' '}
          <StyledSavingsCardBanner.Link onClick={onRxmLinkClick} vpTheme={theme} to={PRESCRIPTION_MANAGEMENT_PATH}>
            Prescription Manager
          </StyledSavingsCardBanner.Link>{' '}
          and look for the “Savings Card Available” label
        </Text>
      </StyledSavingsCardBanner.TextContainer>
    </StyledSavingsCardBanner.Container>
  )
}

export default SavingsCardBanner
